<template>
    <div class="showPatientShareTemplate_container" :class="{'showPatientShareTemplate_container_only_background':isOnlyTitle()}">
        <div class="showPatientShareTemplate_title" v-if="hasTitle()" :class="isOnlyTitle()?'showPatientShareTemplate_only_title':'showPatientShareTemplate_not_only_title'">{{this.pageItem.content[0].class_content}}</div>
        <div class="showPatientShareTemplate_content" :class="!hasImgs()?'showPatientShareTemplate_content_noImg':''" v-if="hasContent() && !hasImgs()">
            <ContentBorder :content="this.pageItem.content[1].class_content" :iconName="getIconImg()" :meetInfo="meetInfo" ></ContentBorder>
        </div>
        <!-- <div class="showPatientShareTemplate_content showPatientShareTemplate_content_hasContentAndImgs" v-if="!hasTitle()&&hasContent()&&hasImgs()">
            <contentBorderTow :content="this.pageItem.content[1].class_content" iconName="content_four.png"></contentBorderTow>
        </div> -->
        <div class="showPatientShareTemplate_content showPatientShareTemplate_content_hasAll" v-if="hasTitle()&&hasContent()&&hasImgs()">
            {{this.pageItem.content[1].class_content}}
        </div>
        <div class="showPatientShareTemplate_images" v-if="hasImgs()">
            <displayImages :images="imgList"></displayImages>
        </div>
        <!-- <div class="global_statement_item">
            <div class="global_statement">{{$config.statement}}</div>
        </div> -->
    </div>
</template>
<script>
import ContentBorder from '@/components/unit/border/contentBorder'
// import contentBorderTow from '@/components/unit/border/contentBorderTow'
import displayImages from '@/components/unit/displayImages'
export default {
    name:"showPatientShareTemplate",
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        meetInfo:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            title:"",
            imgList:[],
            iconList:['content_one.png','content_tow.png','content_three.png',],
            data:{
                
            }
        }
    },
    created(){
        this.getImages()
    },
    mounted(){

    },
    components:{
        ContentBorder,
        // contentBorderTow,
        displayImages
    },
    methods:{
        hasTitle(){
            return !!(this.pageItem.content[0].class_content)
        },
        hasContent(){
            return !!(this.pageItem.content[1].class_content)
        },
        hasImgs(){
            return !!(this.pageItem.content[2].class_content)
        },
        isOnlyTitle(){
            return this.pageItem.content[1].class_content||this.pageItem.content[2].class_content?false:true
        },
        getIconImg(){
            let item = this.$config.theme[this.meetInfo.main_color];
            let prefix = item.name;
            let image = this.iconList[this.pageItem.systemIndex%3];
            return prefix ? `${prefix}_${image}` : Image
        },
        getImages(){
            this.imgList = this.pageItem.content[2].class_content.split(",")
        }
    }
}
</script>
<style>
.showPatientShareTemplate_container{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.showPatientShareTemplate_container_only_background{
    background: #434343;
}
.showPatientShareTemplate_title{
    width: 100%;
}
.showPatientShareTemplate_not_only_title{
    flex: 16.4vw 0 0;
    font-size:4.2vw;
    color: #fff;
    display: flex;
    align-items: center;
    padding:0 5.6vw;
    background:linear-gradient(#80b25f,#becf9c) ;
}
.showPatientShareTemplate_only_title{
    font-size: 4.8vw;
    font-weight: 600;
    background: #fff;
    flex: 27vw 0 0;
    color: #80b25f;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showPatientShareTemplate_content{
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap;
    padding:5vw;
    background:linear-gradient(#fff,#dff1bb);
}
.showPatientShareTemplate_content_hasAll{
    flex: 20.8vw 0 0;
    padding: 0 6vw;
    display: flex;
    align-items: center;
    background:#fff
}
.showPatientShareTemplate_content_noImg{
    flex: 1 0 0;
    display: flex;
    align-items: center;
    padding: 0 6vw;
}
/* .showPatientShareTemplate_content_hasContentAndImgs{
    flex: 39.3vw 0 0;
} */
.showPatientShareTemplate_images{
    flex:1 0 0;
}
</style>